<template>
  <div>
    <Row :gutter="6" class="m-b-10">
      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-select size="small" v-model="query.assetId" @on-change="handleChangeAsset">
          <i-option :value="item.id" v-for="item in assetArray" :key="item.id">{{ item.name }}</i-option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-select size="small" placeholder="站点" clearable v-model="query.stationId">
          <i-option :value="item.id" v-for="item in stationArray" :key="item.id">{{ item.name }}</i-option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-select size="small" placeholder="设备大类" v-model="query.deviceBigmodel" @on-change="handleChangeBigmodel">
          <Option v-for="item in deviceTypeArray" :value="item.id" :key="'type' + item.id">{{ item.name }}</Option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-select size="small" placeholder="设备小类" clearable v-model="query.devicemodel">
          <Option v-for="item in deviceSmallTypeArray" :value="item.id" :key="'type' + item.id">{{ item.name }}</Option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-select size="small" placeholder="状态" clearable v-model="query.enabled">
          <i-option v-for="item in statusArray" :key="'status_' + item.id" :value="item.id">{{ item.name }}</i-option>
        </i-select>
      </i-col>

      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <i-input size="small" placeholder="关键字：支持名称查询" v-model="query.keyword"></i-input>
      </i-col>
      <i-col span="4" class="m-b-5">
        <i-button class="m-r-5" size="small" type="primary" icon="ios-search" @click="handleSearch()">搜索</i-button>
        <Button size="small" type="success" @click="handleDownload">设备导出</Button>
      </i-col>
    </Row>

    <Table size="small" max-height="420" stripe :data="list" :columns="tableColumns"></Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber"
        @on-change="handlePageChanged"></Page>
    </div>
    <Modal v-model="showRecordModal" width="1000" footer-hide>
      <statusDetails v-if="showRecordModal" />
    </Modal>
    <export-device ref="exportDevice" :assetArray="assetArray"></export-device>
    <EditDevice ref="editDevice" :deviceInfo="curDevice" :onSuccessFun="getPage"></EditDevice>
  </div>
</template>

<script>
// import resourceDetail from '@/components/stock/bottomComponents/Detail'
import statusDetails from './StatusDetails'
import ExportDevice from '@/components/device/common/ExportDevice.vue'
import EditDevice from './EditDevice.vue'

// import { getDevicePage } from '@/api/product/device'
import { getMaintenanceAssetList, getExistDeviceModelList } from '@/api/rim/asset'
// import { getStationByAsset } from '@/api/product/station'
// import { updateResourceStatus } from '@/api/product/resource'
// import { getAssignManitainTask } from '@/api/msp/device'
// import { formatEnable } from '@/utils/tagStatus'

/** new api */
import { getRimStationByAssetIds } from '@/api/rim/station'
import { getStatusList, getCompanyDevicePage } from '@/api/rim/device'
export default {
  components: {
    // resourceDetail,
    statusDetails,
    ExportDevice,
    EditDevice
  },
  data () {
    return {
      assetArray: [], // 资产清单
      stationArray: [], // 站点清单
      deviceTypeArray: [], // 设备大类
      deviceSmallTypeArray: [], // 设备小类

      showDetailsModal: false,
      showRecordModal: false,
      chooseResourceId: 0, // 当前选中的资源ID

      showEditStatusModal: false,
      statusModel: {
        enabled: -1,
        fault: false,
        resourceId: null,
        desc: ''
      },
      statusArray: [],

      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15,
        keyword: '',
        assetId: null,
        deviceBigmodel: null,
        inScope: true,
        devicemodel: null,
        stationId: null,
        enabled: null,
        fault: null,
        graph: null
      },
      list: [],
      tableColumns: [
        { title: '编号', align: 'center', key: 'code' },
        { title: '站点', align: 'center', key: 'stationName' },
        { title: '类型', align: 'center', key: 'devicemodelName' },
        { title: '楼层', align: 'center', key: 'floor' },
        { title: '位置', align: 'center', key: 'positionName' },
        { title: '主管单位', align: 'center', key: 'manageUnitName' },
        { title: '状态', align: 'center', key: 'enabledName' },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.handleEditStatus(params.row)
                  }
                }
              }, '编辑状态'),
              h('a',
                {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.chooseResourceId = params.row.id
                      this.$store.commit('set_show_tab', '4')
                      this.$store.commit('set_equipment_detailId', params.row.id)
                      this.showRecordModal = true
                    }
                  }
                }, '详情')
            ])
          }
        }
      ],
      companyId: this.$store.getters.token.userInfo.companyId,
      publisherId: this.$store.getters.token.userInfo.publisherId,
      curDevice: {}
    }
  },
  created () {
    this.initAssetArray()
    this.getStatusData()
  },
  methods: {
    // 初始化资产选项，并默认选中第一条
    initAssetArray () {
      getMaintenanceAssetList().then((res) => {
        if (res && !res.errcode) {
          this.assetArray = res
          if (this.assetArray.length) {
            this.$store.commit('set_equipment_assetArray', res.map(x => x.id))
            this.query.assetId = this.assetArray[0].id
          }
        }
      }).then(() => {
        this.handleChangeAsset()
      })
    },
    // 资产变更
    handleChangeAsset () {
      this.$store.commit('set_selected_asset_id', this.query.assetId)
      this.initStationArray()
      this.initDeviceTypeArray()
    },
    // 初始化站点选项
    initStationArray () {
      this.query.stationId = null
      getRimStationByAssetIds({ assetIds: JSON.stringify([this.query.assetId]) }).then((res) => {
        this.stationArray = res
      })
    },
    // 初始化设备类型选项
    async initDeviceTypeArray () {
      this.query.devicemodel = null
      this.deviceTypeArray = await getExistDeviceModelList({ assetId: this.query.assetId }) || []
      if (this.deviceTypeArray.length) {
        this.query.deviceBigmodel = this.deviceTypeArray[0].id
        this.handleChangeBigmodel()
      }
      this.handleSearch()
    },
    handleChangeBigmodel () {
      this.deviceSmallTypeArray = this.deviceTypeArray.find(x => x.id === this.query.deviceBigmodel).children || []
    },
    getStatusData () {
      getStatusList().then(res => {
        if (res && !res.errcode) {
          this.statusArray = res
          this.$store.commit('set_device_status_array', res)
        }
      })
    },
    // 分页
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.getPage()
    },
    // 搜索
    handleSearch () {
      this.query.pageNumber = 1
      this.$store.commit('set_cur_category', null)
      const { assetId, deviceBigmodel, devicemodel, stationId, enabled, keyword } = this.query
      this.$store.dispatch('getDeviceStatusStaData', { assetId, deviceBigmodel, devicemodel, stationId, enabled, keyword })
      this.$store.dispatch('getDeviceReportTroubleData', { assetId: this.query.assetId })
      // 获取SVG标注的数据
      this.$store.dispatch('getTroubleReportStationData', { assetId: this.query.assetId, category: null })
      this.getPage()
      // this.getSvgData()
    },
    // 获取分页列表
    async getPage () {
      await getCompanyDevicePage(this.query).then((res) => {
        res.list.map((item) => {
          item.visible = item.visibleScope === 2
          return item
        })
        this.list = res.list
        this.total = res.totalRow
      })
    },
    // /**
    //  * 发起中断事件
    //  */
    // handleInterrupt (data) {
    //   this.statusModel.enabled = data.enabled
    //   this.statusModel.fault = data.fault
    //   this.statusModel.resourceId = data.id
    //   this.showEditStatusModal = true
    // },
    // handleSubmitEditStatus () {
    //   if (this.statusModel.enabled < 0) {
    //     this.$Notice.error({ desc: '请选择资源状态' })
    //     return false
    //   }
    //   this.$Modal.confirm({
    //     title: '操作提示',
    //     desc: '确定要提交本次操作？',
    //     onOk: () => {
    //       this.showEditStatusModal = false
    //       updateResourceStatus(this.statusModel).then(res => {
    //         if (res && res.errcode === 0) {
    //           this.getPage()
    //           this.$Notice.success({ desc: '操作成功' })
    //         }
    //       })
    //     }
    //   })
    // },
    handleShowRecord (id) {
      this.$store.commit('set_equipment_detailId', id)
      // this.$store.dispatch('setShowTechParams', { resourceId: id })

      this.showRecordModal = true
    },
    handleDownload () {
      this.$refs.exportDevice.handleDownload()
    },
    handleEditStatus (params) {
      this.curDevice = params
      this.$nextTick(() => {
        this.$refs.editDevice.init()
      })
    }
  }
}
</script>
